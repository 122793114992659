import React, { memo, useEffect } from "react";
import { LayoutLanding } from "@app/layouts";
import {
  SectionGPS,
  SectionInfo,
  SectionIntegration,
  SectionPartners,
  SectionPossibilities,
  SectionUsage,
  SectionWelcome,
} from "./components";
import { useUser } from "@app/providers";
import { useNavigate } from "react-router-dom";

interface Props {}

function PageLading(_props: Props) {
  const navigate = useNavigate();
  const { authenticated } = useUser();

  useEffect(() => {
    if (authenticated) {
      navigate("/main");
    }
  }, [authenticated, navigate]);

  return (
    <LayoutLanding>
      <SectionWelcome />
      <SectionInfo />
      <SectionPossibilities />
      <SectionGPS />
      <SectionIntegration />
      <SectionUsage />
      <SectionPartners />
    </LayoutLanding>
  );
}

export default memo(PageLading);
