import { LayoutDefault } from "@app/layouts";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  ActDetail,
  ActListItem,
  ActStatus,
  ActStatusName,
  Company,
  ContractActParams,
  Project,
  SmartContract,
} from "@app/models";
import { TemplateSearch, TemplateSearchModalDetail } from "@app/templates";
import {
  APIResponse,
  deleteAct,
  getActById,
  getActs,
  getCompanies,
  getContractsForAct,
  getMyCompanies,
  getMyProjects,
  getProjects,
  PageableParams,
  PageableResponse,
} from "@app/api";
import { format } from "date-fns";
import {
  Button,
  Combobox,
  DateTimePicker,
  DefaultObject,
  Dialog,
  TextField,
} from "@app/components";
import {
  companyLabelKeys,
  enumToArray,
  getAxiosErrorMessage,
  getDateFilterValue,
  getFilterValues,
  getFullName,
  isDispatcher,
  isPartner,
  priceFormat,
} from "@app/helpers";
import { IconInfo20, IconPlus20, IconTrash24 } from "@app/icons";
import {
  ModalActOfServicesCreate,
  ModalActOfServicesDetail,
  ModalAddActInstruction,
} from "@app/modals";
import { useNotification, useUser } from "@app/providers";
import {
  NotificationTypeNames,
  NotificationTypes,
} from "../../providers/Firebase/Firebase";
import { AxiosError } from "axios";
import { ActStatusChip, MoreMenu, SmartContractStatusBadge } from "@app/common";
import { useDebounce } from "@app/hooks";
import styled from "styled-components";

const FILTER_STORAGE_KEY = "actOfProvidedServicesFilters";

function DetailModal(props: TemplateSearchModalDetail<ActListItem>) {
  const { item: request, open, onClose, updateItem } = props;
  const { user } = useUser();

  const isEditable = useMemo(
    () =>
      request &&
      [ActStatus.Created, ActStatus.ActRevoked, ActStatus.Rejected].indexOf(
        request.status
      ) > -1,
    [request]
  );

  if (!open || !request) {
    return null;
  }

  return isEditable && user && !isPartner(user?.role) ? (
    <ModalActOfServicesCreate
      open={open}
      size={"xlarge"}
      // @ts-ignore
      data={request}
      onClose={onClose}
      updateData={updateItem}
    />
  ) : (
    <ModalActOfServicesDetail
      open={open}
      size={"xlarge"}
      data={request}
      onClose={onClose}
    />
  );
}

interface FilterParamsProps {
  numbers: string[];
  companies: Company[] | null;
  partners: Company[] | null;
  projects: Project[] | null;
  contracts: Array<SmartContract> | null;
  statuses: DefaultObject<ActStatus>[] | null;
  startDate: Date | null;
  endDate: Date | null;
  summa: string;
}

interface FilterValuesProps {
  ActNumbers?: string[];
  CompanyIds: string[];
  PartnerIds: string[];
  ProjectIds: string[];
  ContractIds: string[];
  Summa?: string;
  Statuses: (string | number)[];
  StartDate?: string;
  EndDate?: string;
}

const initialFiltersParams = JSON.stringify({
  numbers: [],
  contracts: null,
  partners: null,
  companies: null,
  projects: null,
  statuses: null,
  startDate: null,
  endDate: null,
  summa: "",
});

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled.div`
  margin-left: 16px;
`;

const moreMenuStatuses = [
  ActStatus.Created,
  ActStatus.Rejected,
  ActStatus.Deleted,
  ActStatus.ActRevoked,
];

function PageActOfProvidedServices() {
  const { asPartner, user } = useUser();

  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [actItem, setActItem] = useState<ActDetail | null>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [contractList, setContractList] = useState<SmartContract[]>([]);
  const [instructionModalVisible, setInstructionModalVisible] =
    useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const { showNotification } = useNotification();

  const onRequestModalClose = useCallback(() => {
    setLoading(true);
    setActItem(null);
    setShowRequestModal(false);
    // Таймаут нужен для обновления таблицы, см. ниже getData()
    setTimeout(() => setLoading(false), 100);
  }, []);

  const updateActItem = useCallback((act: ActDetail | null) => {
    setActItem(act);
  }, []);

  const closeInstructionModal = useCallback(() => {
    setInstructionModalVisible(false);
  }, []);

  const onClickInstruction = useCallback(() => {
    setInstructionModalVisible(true);
  }, []);

  const onClickAdd = useCallback(() => {
    setShowRequestModal(true);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    if (!actItem?.id) {
      return;
    }
    try {
      const response = await deleteAct(actItem?.id);
      if (response && response.data) {
        setActItem(null);
        setShowDialog(false);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    } catch (e) {
      setLoading(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [actItem, showNotification]);

  const tableLabels = useMemo(
    () => [
      "№  акта",
      "Дата создания",
      "Организация",
      "Проект",
      "Исполнитель/Контрагент",
      "Сумма",
      "Статус",
      "Дата начала\nработы техники",
      "Дата окончания\nработы техники",
      "Инициатор",
      "Договор",
      "",
    ],
    []
  );

  const menuItems = useCallback(
    (item: ActListItem) => [
      {
        icon: IconTrash24,
        label: "Удалить",
        onClick: () => {
          // @ts-ignore
          setActItem(item);
          setShowDialog(true);
        },
      },
    ],
    []
  );

  const mapTableData = useCallback(
    (item: ActListItem) => {
      return [
        item.actNumber,
        format(new Date(item.createdDate), "dd.MM.yyyy"),
        item.company.name,
        item.project.name,
        item.partner.name,
        priceFormat(item.sum.toString()),
        <ActStatusChip status={item.status} />,
        format(new Date(item.startWorkDate), "dd.MM.yyyy"),
        format(new Date(item.endWorkDate), "dd.MM.yyyy"),
        item.initiatorUserName ?? getFullName(item.initiatorUser),
        <SmartContractStatusBadge
          status={item.contract?.status}
          title={item.contract?.name}
          subtitle={"—"}
        />,
        !asPartner && moreMenuStatuses.includes(item.status) && (
          <MoreMenu items={menuItems(item)} />
        ),
      ];
    },
    [asPartner, menuItems]
  );

  const leftControls = useMemo(
    () => [
      <ButtonsRow>
        {!asPartner && (
          <Button
            text="Создать табель"
            startIcon={IconPlus20}
            onClick={onClickAdd}
          />
        )}
        {asPartner && (
          <StyledButton>
            <Button
              text="Смотреть инструкцию"
              variant="newDesign"
              startIcon={IconInfo20}
              onClick={onClickInstruction}
            />
          </StyledButton>
        )}
      </ButtonsRow>,
    ],
    [asPartner, onClickAdd, onClickInstruction]
  );

  useEffect(() => {
    const obj: ContractActParams = {
      customerId: getFilterValues(filterParams.companies)[0],
      projectId: getFilterValues(filterParams.projects)[0],
      partnerId: getFilterValues(filterParams.partners)[0],
    };

    const valid = Object.values(obj).every(Boolean);
    if (valid) {
      getContractsForAct(obj).then(({ data }) => setContractList(data));
    } else {
      setContractList([]);
    }
  }, [filterParams.companies, filterParams.partners, filterParams.projects]);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        ActNumbers: filterParams.numbers || undefined,
        CompanyIds: getFilterValues(filterParams.companies),
        ContractIds: getFilterValues(filterParams.contracts),
        PartnerIds: getFilterValues(filterParams.partners),
        ProjectIds: getFilterValues(filterParams.projects),
        Statuses: getFilterValues(filterParams.statuses),
        StartDate: getDateFilterValue(filterParams.startDate),
        EndDate: getDateFilterValue(filterParams.endDate),
        Summa: filterParams.summa || undefined,
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox
        label="Номер акта"
        placeholder="Укажите номер"
        name="numbers"
        compliant={true}
        values={filterParams.numbers}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Организация"
        name="companies"
        onChange={onFilterChange}
        values={filterParams.companies}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Company>
        label="Контрагент"
        name="partners"
        onChange={onFilterChange}
        values={filterParams.partners}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Project>
        label="Проект"
        name="projects"
        onChange={onFilterChange}
        values={filterParams.projects}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <Combobox<SmartContract>
        label="Договор"
        name="contracts"
        values={filterParams.contracts}
        options={contractList}
        onChange={onFilterChange}
      />,
      <Combobox<DefaultObject<ActStatus>>
        label="Статус"
        name="statuses"
        onChange={onFilterChange}
        values={filterParams.statuses}
        options={enumToArray(ActStatusName)}
      />,
      <TextField
        label="Сумма"
        placeholder="Укажите сумму"
        name="summa"
        type="number"
        onChange={onFilterChange}
        value={filterParams.summa}
      />,
      <DateTimePicker
        label="Период, от"
        name="startDate"
        onChange={onFilterChange}
        value={filterParams.startDate}
        hideTime
      />,
      <DateTimePicker
        label="Период, до"
        name="endDate"
        onChange={onFilterChange}
        value={filterParams.endDate}
        hideTime
      />,
    ],
    [contractList, filterParams, onFilterChange, user]
  );

  const getData = useCallback(
    (params: PageableParams) => {
      if (loading) {
        return new Promise<PageableResponse<ActListItem>>(() => []);
      }
      return getActs(params);
    },
    [loading]
  );

  return (
    <LayoutDefault title="Табель (расшифровка оказанных услуг)">
      <TemplateSearch<ActListItem>
        getData={getData}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        toolbarProps={{
          leftControls,
          searchPlaceholder: "№ табеля, организация, проект, контрагент",
        }}
        getItemDetailData={getActById}
        modalDetail={DetailModal}
        refreshEventName={NotificationTypeNames[NotificationTypes.ActCreated]}
      />
      <ModalActOfServicesCreate
        open={showRequestModal}
        size={"xlarge"}
        data={actItem}
        onClose={onRequestModalClose}
        updateData={updateActItem}
      />
      <ModalAddActInstruction
        open={instructionModalVisible}
        onClose={closeInstructionModal}
      />
      <Dialog
        open={showDialog}
        title={`Удалить табель оказанных услуг №${actItem?.actNumber}?`}
        icon={IconTrash24}
        onConfirm={onDeleteConfirm}
        onClose={() => {
          setShowDialog(false);
        }}
      />
    </LayoutDefault>
  );
}

export default memo(PageActOfProvidedServices);
