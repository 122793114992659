import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";

export const StyledStep = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: 1fr auto;
  position: relative;
  padding: 16px 24px;
  box-sizing: border-box;
`;

export const StyledContent = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;
`;

export const StyledBox = styled.div<{
  transparent?: boolean;
}>`
  display: grid;
  gap: 12px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  position: relative;

  ${switchProp("transparent", {
    true: css`
      border-color: ${theme("color.grayLight")};
    `,
    false: css`
      background-color: #f5f6f8;
      border-color: #f5f6f8;
    `,
  })}
`;

export const StyledStatus = styled.div`
  width: max-content;
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const StyledBoxTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${theme("color.dark")};
`;

export const StyledBoxText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

export const StyledBoxStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledFileName = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${theme("color.dark")};
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 8px 0;
`;

export const StyledStep2 = styled.div`
  display: grid;
  grid-gap: 0;
  height: 100%;
  grid-template-rows: auto 1fr auto auto;
  position: relative;
  padding: 16px 0;
  box-sizing: border-box;
  overflow: hidden;
`;

export const StyledHeader = styled.div`
  padding: 0 24px;
  margin-bottom: 16px;
  box-sizing: border-box;
`;

export const StyledContentStep2 = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;
  padding: 0 24px;
  box-sizing: border-box;
  overflow-y: auto;
`;

export const StyledNomenclature = styled.div<{
  withRemoveButton: boolean;
}>`
  display: grid;
  gap: 16px;
  align-items: flex-end;
  ${switchProp("withRemoveButton", {
    true: css`
      grid-template-columns: 46px auto 220px 150px 96px 116px 116px 116px max-content;
    `,
    false: css`
      grid-template-columns: 46px auto 220px 150px 96px 116px 116px 116px;
    `,
  })}
`;

export const StyledRemoveButton = styled.div`
  .b-button {
    color: ${theme("color.red")} !important;
    border: none;
  }
`;

export const StyledTotal = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 96px 116px 116px 116px;
  gap: 16px;
  align-items: center;
`;

export const StyledTotalText = styled.p`
  font-size: 20px;
  color: ${theme("color.dark")};
  font-weight: 600;
  text-align: right;
`;

export const StyledFooter = styled.div`
  padding: 16px 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${theme("color.grayLight")};
  justify-content: flex-end;
`;
