import React, {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BaseButton,
  Button,
  Grid,
  Modal,
  ModalProps,
  ModalTitle,
  Tabs,
} from "@app/components";
import ListItem from "../../components/List/ListItem";
import TaskAOS from "./components/TaskAOS";
import {
  ActDetail,
  SmartContract,
  Task,
  UserTaskStatuses,
  UserTaskType,
} from "@app/models";
import { acquaintedTask, APIResponse, getActById, getTaskById } from "@app/api";
import { dateFormat, getAxiosErrorMessage } from "@app/helpers";
import ActApprovalListView from "./components/ApprovalList";
import { useNotification } from "@app/providers";
import TaskStatusChip from "../../common/TaskStatusChip/TaskStatusChip";
import { AxiosError } from "axios";
import { ConstructiveContext } from "@app/common";
import styled from "styled-components";
import { ModalContractDocDetail } from "../index";

interface Props extends Omit<ModalProps, "title"> {
  data: Task | null;
  onUserRedirect: () => void;
  onApprove: () => void;
  onReject: () => void;
}

const StyledContractTitle = styled.p<{ clickable: boolean }>`
  text-align: start;
  text-decoration: ${(props) => (props.clickable ? "underline" : "initial")};
`;

const ModalActTaskDetail = (props: Props) => {
  const { onClose, onUserRedirect, data, onApprove, ...restProps } = props;
  const { showNotification } = useNotification();
  const [detail, setDetail] = useState<Task | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [actData, setActData] = useState<ActDetail | null>(null);
  const { setRequestList } = useContext(ConstructiveContext);
  const [contractId, setContractId] = useState<SmartContract["id"] | null>(
    null
  );
  const [constructiveAdded, setConstructiveAdded] = useState<boolean>(false);

  const taskAOSRef = useRef<{ handleApprove: () => void } | null>(null);

  const getTaskDetail = useCallback(
    async (id: string) => {
      try {
        const response = await getTaskById(id);
        setDetail(response.data);
      } catch (error) {
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  const getActData = useCallback(
    async (actId: string) => {
      try {
        const response = await getActById(actId);
        setActData(response.data);
        const arr = response.data.actConstructiveDto?.map(
          ({ constructive, sum, projectSite }) => ({
            constructive: {
              value: constructive.id,
              label: constructive.name || "-",
            },
            projectSite: {
              value: "",
              label: projectSite || "-",
            },
            isNewProjectSite: false,
            sum,
          })
        );
        setRequestList(arr ?? []);
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [setRequestList, showNotification]
  );

  useEffect(() => {
    if (!data) return;
    getTaskDetail(data.id);
    getActData(data.sourceId);
  }, [data, getActData, getTaskDetail]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return null;
    }

    return (
      <ModalTitle text={data.name}>
        {!!data.status && <TaskStatusChip status={data.status} />}
      </ModalTitle>
    );
  }, [data]);

  const tabLabels = useMemo<string[]>(
    () => ["Табель оказанных услуг", "Лист согласования"],
    []
  );

  const onClickApprove = useCallback(() => {
    if (taskAOSRef.current) {
      if (constructiveAdded) {
        taskAOSRef.current.handleApprove();
      } else {
        onApprove();
      }
    }
  }, [constructiveAdded, onApprove]);

  const onClickAcquainte = useCallback(() => {
    if (!data) {
      return;
    }

    setLoading(true);
    acquaintedTask(data.id)
      .then(() => {
        showNotification({
          variant: "success",
          message: "Ознакомлен",
        });
        onClose();
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e),
          variant: "error",
        });
      });
  }, [data, onClose, showNotification]);

  const onClickReject = useCallback(() => {
    if (!data) {
      return;
    }

    props.onReject();
  }, [data, props]);

  const onClickRedirect = useCallback(() => {
    if (!data) {
      return;
    }
    onUserRedirect();
  }, [data, onUserRedirect]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    if (data?.taskType === UserTaskType.AcquaintanceAct) {
      returnActions.push(
        <Button
          text="Ознакомлен"
          onClick={onClickAcquainte}
          showLoader={loading}
        />
      );
      return returnActions;
    }
    if (
      data?.status === UserTaskStatuses.ToBeAgreed ||
      data?.status === UserTaskStatuses.OnApprove
    ) {
      returnActions.push(
        <Button
          text="Перенаправить"
          showLoader={loading}
          variant={"text"}
          onClick={onClickRedirect}
        />,
        <Button
          text="Отклонить"
          showLoader={loading}
          variant={"outlined"}
          onClick={onClickReject}
        />,
        <Button
          text="Согласовать"
          onClick={onClickApprove}
          showLoader={loading}
        />
      );
    }
    return returnActions;
  }, [
    data,
    loading,
    onClickAcquainte,
    onClickApprove,
    onClickRedirect,
    onClickReject,
  ]);

  const showContractDetail = useCallback(async () => {
    if (!actData?.contract.contractDraftId) {
      return;
    }
    setContractId(actData.contract.contractDraftId);
  }, [actData?.contract.contractDraftId]);

  const onModalClose = useCallback(() => {
    setDetail(null);
    onClose();
  }, [onClose]);

  const handleConstructiveAdded = useCallback(() => {
    setConstructiveAdded(true);
  }, []);

  const handleConstructiveRemoved = useCallback(() => {
    setConstructiveAdded(false);
  }, []);

  return (
    <React.Fragment>
      <Modal
        zIndex={999}
        title={modalTitle}
        actions={actions}
        onClose={onModalClose}
        {...restProps}
      >
        <div className="">
          <Grid columns={5}>
            <ListItem subtitle="Организация" title={detail?.customer?.name} />
            <ListItem subtitle="Проект" title={detail?.project.name} />
            <ListItem subtitle="Исполнитель" title={detail?.executor.name} />
            <ListItem
              title={
                <BaseButton
                  disabled={!actData?.contract.contractDraftId}
                  onClick={showContractDetail}
                >
                  <StyledContractTitle
                    clickable={!!actData?.contract.contractDraftId}
                  >
                    {actData?.contract?.name}
                  </StyledContractTitle>
                </BaseButton>
              }
              subtitle={"Договор"}
            />
            <ListItem
              subtitle="Дата отправки"
              title={dateFormat(detail?.dateStart ?? null, "dd.MM.yyyy")}
            />
          </Grid>
        </div>
        <Tabs labels={tabLabels}>
          <TaskAOS
            ref={taskAOSRef}
            taskData={detail}
            actData={actData}
            onApprove={onApprove}
            onConstructiveAdded={handleConstructiveAdded}
            onConstructiveRemoved={handleConstructiveRemoved}
          />
          <ActApprovalListView actId={data?.sourceId} />
        </Tabs>
      </Modal>
      <ModalContractDocDetail
        open={!!contractId}
        contractId={contractId}
        size={"medium"}
        onClose={() => setContractId(null)}
      />
    </React.Fragment>
  );
};

export default memo(ModalActTaskDetail);
