import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActOfWork,
  ActOfWorkStatus,
  ActOfWorkStatusName,
  Company,
  ContractActParams,
  Project,
  SmartContract,
  UserRoles,
} from "@app/models";
import { format } from "date-fns";
import {
  companyLabelKeys,
  enumToArray,
  getAxiosErrorMessage,
  getDateFilterValue,
  getFilterValues,
  isDispatcher,
  priceFormat,
} from "@app/helpers";
import {
  Button,
  Combobox,
  DateTimePicker,
  DefaultObject,
  TextField,
} from "@app/components";
import { IconInfo20, IconPlus20 } from "@app/icons";
import { LayoutDefault } from "@app/layouts";
import { TemplateSearch } from "@app/templates";
import {
  APIResponse,
  getActOfProvidedWorks,
  getActOfWorksById,
  getCompanies,
  getContractsForAct,
  getMyCompanies,
  getMyProjects,
  getProjects,
} from "@app/api";
import {
  ModalActOfWorkDetail,
  ModalAddActOfProvidedWorksInstruction,
} from "@app/modals";
import ActOfWorkStatusChip from "../../common/ActOfWorkStatusChip";
import { useNotification, useUser } from "@app/providers";
import { useDebounce } from "@app/hooks";
import { AxiosError } from "axios";
import styled from "styled-components";

const FILTER_STORAGE_KEY = "actOfProvidedWorksFilters";

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled.div`
  margin-left: 16px;
`;

interface Props {}

interface FilterParamsProps {
  number: string;
  actNumber: string;
  registrationNumber: string;
  senderCompany: Array<Company> | null;
  recipientCompany: Array<Company> | null;
  project: Array<Project> | null;
  contract: Array<SmartContract> | null;
  status: DefaultObject<ActOfWorkStatus>[] | null;
  createdDateMin: Date | null;
  startDateMin: Date | null;
  endDateMax: Date | null;
}

interface FilterValuesProps {
  Number?: string;
  ActNumber?: string;
  SenderCompanyIds: string[];
  RecipientCompanyIds: string[];
  ProjectIds: string[];
  ActIds?: string[];
  ContractIds?: string[];
  RegistrationNumber?: string;
  StatusIds: (string | number)[];
  CreatedDateMin?: string;
  StartDateMin?: string;
  EndDateMax?: string;
}

const initialFiltersParams = JSON.stringify({
  actNumber: "",
  number: "",
  contract: null,
  project: null,
  recipientCompany: null,
  registrationNumber: "",
  senderCompany: null,
  status: null,
  createdDateMin: null,
  startDateMin: null,
  endDateMax: null,
});

function PageActOfProvidedWorks(_props: Props) {
  const { user, asPartner } = useUser();
  const { showNotification } = useNotification();
  const [actOfWork, setActOfWork] = useState<ActOfWork | null>(null);
  const [modalDetailVisible, setModalDetailVisible] = useState<boolean>(false);
  const [contractList, setContractList] = useState<SmartContract[]>([]);
  const [instructionModalVisible, setInstructionModalVisible] =
    useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const onClickAdd = useCallback(() => {
    setModalDetailVisible(true);
  }, []);

  const closeInstructionModal = useCallback(() => {
    setInstructionModalVisible(false);
  }, []);

  const onClickInstruction = useCallback(() => {
    setInstructionModalVisible(true);
  }, []);

  const onCloseModalDetails = useCallback(() => {
    setActOfWork(null);
    setModalDetailVisible(false);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("avrId");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, []);

  const onClickActOfWork = useCallback((item: ActOfWork) => {
    setActOfWork(item);
    setModalDetailVisible(true);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("avrId", item.id);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    for (const [key, value] of queryParams) {
      if (key === "avrId") {
        getActOfWorksById(value)
          .then((res) => {
            if (res.succeeded) {
              onClickActOfWork(res.data);
            }
          })
          .catch((e) => {
            showNotification({
              message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
              variant: "error",
            });
          });
      }
    }
  }, [onClickActOfWork, showNotification]);

  const tableLabels = useMemo(
    () => [
      "№  акта",
      "Дата создания",
      "Номер АВР",
      "Исполнитель/Контрагент",
      "Проект",
      "Заказчик",
      "Сумма",
      "Статус АВР",
      "Дата начала\nработы техники",
      "Дата окончания\nработы техники",
      "Договор",
    ],
    []
  );

  const mapTableData = useCallback((item: ActOfWork) => {
    return [
      item.number,
      format(new Date(item.createdDate), "dd.MM.yyyy"),
      item?.registrationNumber ?? "-",
      item.senderCompany.name,
      item.project.name,
      item.recipientCompany.name,
      priceFormat(item.totalSum.toString()),
      <ActOfWorkStatusChip status={item.statusId!} />,
      format(new Date(item.startDate), "dd.MM.yyyy"),
      format(new Date(item.endDate), "dd.MM.yyyy"),
      item?.contract.name ?? "-",
    ];
  }, []);

  const leftControls = useMemo(() => {
    if (
      [UserRoles.PARTNER, UserRoles.DISPATCHER].indexOf(
        user!.role.toUpperCase() as UserRoles
      ) === -1
    ) {
      return [];
    }

    return [
      <ButtonsRow>
        <Button
          text="Создать акт"
          startIcon={IconPlus20}
          onClick={onClickAdd}
        />
        {asPartner && (
          <StyledButton>
            <Button
              text="Смотреть инструкцию"
              variant="newDesign"
              startIcon={IconInfo20}
              onClick={onClickInstruction}
            />
          </StyledButton>
        )}
      </ButtonsRow>,
    ];
  }, [user, onClickAdd, asPartner, onClickInstruction]);

  useEffect(() => {
    const obj: ContractActParams = {
      customerId: getFilterValues(filterParams.recipientCompany)[0],
      projectId: getFilterValues(filterParams.project)[0],
      partnerId: getFilterValues(filterParams.senderCompany)[0],
    };

    const valid = Object.values(obj).every(Boolean);
    if (valid) {
      getContractsForAct(obj).then(({ data }) => setContractList(data));
    } else {
      setContractList([]);
    }
  }, [
    filterParams.project,
    filterParams.recipientCompany,
    filterParams.senderCompany,
  ]);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        Number: filterParams.number || undefined,
        RegistrationNumber: filterParams.registrationNumber || undefined,
        RecipientCompanyIds: getFilterValues(filterParams.recipientCompany),
        SenderCompanyIds: getFilterValues(filterParams.senderCompany),
        ProjectIds: getFilterValues(filterParams.project),
        ContractIds: getFilterValues(filterParams.contract),
        StatusIds: getFilterValues(filterParams.status),
        CreatedDateMin: getDateFilterValue(filterParams.createdDateMin),
        StartDateMin: getDateFilterValue(filterParams.startDateMin),
        EndDateMax: getDateFilterValue(filterParams.endDateMax),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <TextField
        label="Номер акта"
        name="number"
        placeholder="Укажите номер"
        onChange={onFilterChange}
        value={filterParams.number}
      />,
      <TextField
        label="Номер АВР"
        name="registrationNumber"
        placeholder="Укажите номер"
        onChange={onFilterChange}
        value={filterParams.registrationNumber}
      />,
      <Combobox<Company>
        label="Исполнитель"
        name="senderCompany"
        onChange={onFilterChange}
        values={filterParams.senderCompany}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Project>
        label="Проект"
        name="project"
        onChange={onFilterChange}
        values={filterParams.project}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <Combobox<Company>
        label="Заказчик"
        name="recipientCompany"
        onChange={onFilterChange}
        values={filterParams.recipientCompany}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<SmartContract>
        label="Договор"
        name="contract"
        values={filterParams.contract}
        options={contractList}
        onChange={onFilterChange}
      />,
      <Combobox<DefaultObject<ActOfWorkStatus>>
        label="Статус"
        name="status"
        onChange={onFilterChange}
        values={filterParams.status}
        options={enumToArray(ActOfWorkStatusName)}
      />,
      <DateTimePicker
        label="Дата создания"
        name="createdDateMin"
        onChange={onFilterChange}
        value={filterParams.createdDateMin}
        hideTime
      />,
      <DateTimePicker
        label="Период, от"
        name="startDateMin"
        onChange={onFilterChange}
        value={filterParams.startDateMin}
        hideTime
      />,
      <DateTimePicker
        label="Период, до"
        name="endDateMax"
        onChange={onFilterChange}
        value={filterParams.endDateMax}
        hideTime
      />,
    ],
    [contractList, filterParams, onFilterChange, user]
  );

  return (
    <LayoutDefault title="Акт выполненных работ (Р-1)">
      <TemplateSearch<ActOfWork>
        toolbarProps={{
          leftControls,
          searchPlaceholder: "№ акта, организация, проект, контрагент",
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        getData={getActOfProvidedWorks}
        onClick={onClickActOfWork}
      />
      <ModalActOfWorkDetail
        open={modalDetailVisible}
        actOfWork={actOfWork}
        onClose={onCloseModalDetails}
      />
      <ModalAddActOfProvidedWorksInstruction
        open={instructionModalVisible}
        onClose={closeInstructionModal}
      />
    </LayoutDefault>
  );
}

export default PageActOfProvidedWorks;
