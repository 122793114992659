import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Modal, ModalProps, Stepper } from "@app/components";
import {
  ActListItem,
  ActOfWork,
  ActOfWorkStatus,
  Dictionary,
} from "@app/models";
import styled from "styled-components";
import { Step1, Step2, Step3 } from "./components";
import { getActById, getVehiclesByActIdV2 } from "@app/api";
import { BasisDictionary } from "./components/Step1/Step1";
import {
  getActOfWorkNomenclatureName,
  getActOfWorkNomenclaturePrice,
  getActOfWorkNomenclatureQuantity,
} from "@app/helpers";

interface Props extends Pick<ModalProps, "open" | "onClose"> {
  actOfWork: ActOfWork | null;
  isActOfService?: boolean;
}

const StyledContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const StyledStepper = styled.div`
  border-bottom: 8px solid #f5f6f8;
  padding: 0 24px;
  box-sizing: border-box;
  height: 90px;
  display: flex;
  align-items: center;

  > * {
    width: 100%;
  }
`;

const StyledDetail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: calc(100% - 90px);
  overflow-y: auto;
  box-sizing: border-box;
`;

const initStatuses = [ActOfWorkStatus.Created, ActOfWorkStatus.Updated];

function ModalActOfWorkDetail(props: Props) {
  const {
    onClose,
    actOfWork: propsActOfWork,
    open,
    isActOfService: propsIsActOfService,
    ...restProps
  } = props;
  const [pending, setPending] = useState<boolean>(true);
  const [isActOfService, setIsActOfService] = useState<boolean>(false);
  const [act, setAct] = useState<ActListItem | null>(null);
  const [actOfWork, setActOfWork] = useState<ActOfWork | null>(null);
  const [basis, setBasis] = useState<BasisDictionary | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const onSubmitBasis = useCallback((data: BasisDictionary | null) => {
    if (!!data) {
      setBasis(data);
    }

    setCurrentStep(2);
  }, []);

  const modalTitle = useMemo(() => {
    if (pending) {
      return "Загружается...";
    }
    if (propsActOfWork?.number) {
      return `Акт №${propsActOfWork.number}`;
    }
    return "Создание АВР";
  }, [pending, propsActOfWork?.number]);

  const steps = useMemo(
    () => ["Выбор основания", "Формирование", "Подписание"],
    []
  );

  const onChangeAct = useCallback(
    async (actData: ActListItem | null) => {
      try {
        if (!actData) {
          setIsActOfService(false);

          setAct(null);
          setActOfWork(null);

          return;
        }

        const nomenclaturesResponse = await getVehiclesByActIdV2(
          actData.id,
          true
        );

        setIsActOfService(true);

        setActOfWork({
          id: "",
          createdDate: new Date().toString(),
          number: null,
          registrationNumber: "",
          date: null,
          startDate: actData.startWorkDate,
          endDate: actData.endWorkDate,
          senderCompanyAddress: "",
          recipientCompanyAddress: "",
          ndsRate: actData.nds?.name || null,
          statusId: null,
          actId: actData.id,
          actNumber: actData.actNumber ?? "",
          senderCompany: {
            ...actData.partner,
            name: actData.partner.name ?? "",
            bin: actData.partner.bin ?? "",
          },
          recipientCompany: {
            ...actData.company,
            name: actData.company.name ?? "",
            bin: actData.company.bin ?? "",
          },
          project: actData.project,
          contract: actData.contract as Dictionary,
          basis,
          signedFile: null,
          totalQuantity: null,
          totalSum: 0,
          totalSumNds: 0,
          files: [],
          signers: [],
          histories: [],
          nomenclatures: nomenclaturesResponse.data.map((item) => ({
            avrId: "",
            name: getActOfWorkNomenclatureName(item),
            executionDate: actData.endWorkDate,
            unit: item.unitDto,
            quantity: getActOfWorkNomenclatureQuantity(item),
            price: getActOfWorkNomenclaturePrice(item),
            sum: item.totalSumma,
            sumNds: item.totalSummaNds || 0,
          })),
          approvers: [],
        });

        setAct(actData);
      } catch (e) {
        console.log(e);
      }
    },
    [basis]
  );

  const onCloseModal = useCallback(() => {
    onClose();
    setCurrentStep(1);
    setBasis(null);
    setActOfWork(null);
    setAct(null);
    setPending(true);
  }, [onClose]);

  const getData = useCallback(async () => {
    try {
      const { data: actData } = await getActById(propsActOfWork!.actId);

      setAct(actData);

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [propsActOfWork]);

  const disabled = useMemo(() => {
    if (isActOfService || !actOfWork?.statusId) {
      return false;
    }

    return !initStatuses.includes(actOfWork.statusId);
  }, [isActOfService, actOfWork]);

  useEffect(() => {
    if (!!propsActOfWork) {
      setActOfWork(propsActOfWork);
      setCurrentStep(
        !!propsActOfWork.statusId &&
          !initStatuses.includes(propsActOfWork.statusId)
          ? 3
          : 1
      );

      if (!!propsActOfWork!.basis) {
        setBasis({
          id: propsActOfWork!.basis.id,
          name: propsActOfWork!.basis.name,
          isActBasis: (propsActOfWork!.basis as BasisDictionary).isActBasis,
        });
      }

      if (!!propsActOfWork.actId) {
        setIsActOfService(!!propsIsActOfService);

        getData();

        return;
      }
    }

    setPending(false);
  }, [getData, propsActOfWork, propsIsActOfService]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      {...restProps}
      open={open}
      title={modalTitle}
      size={currentStep === 2 ? "xlarge" : "medium"}
      dense
      onClose={onCloseModal}
    >
      <StyledContent>
        <StyledStepper>
          <Stepper steps={steps} currentStep={currentStep} />
        </StyledStepper>
        <StyledDetail>
          {currentStep === 1 && (
            <Step1
              onSubmit={onSubmitBasis}
              basis={basis}
              disabled={!!propsIsActOfService || disabled}
              act={act}
              setAct={onChangeAct}
              actOfWorkStatus={actOfWork?.statusId || null}
            />
          )}
          {currentStep === 2 && (
            <Step2
              onChange={setCurrentStep}
              basis={basis}
              actOfWork={
                !!actOfWork
                  ? {
                      ...actOfWork,
                      actId: act?.id ?? "",
                      actNumber: act?.actNumber ?? "",
                    }
                  : null
              }
              setActOfWork={setActOfWork}
              isActOfService={isActOfService}
              disabled={disabled}
            />
          )}
          {currentStep === 3 && actOfWork && (
            <Step3
              actOfWork={{
                ...actOfWork,
                actId: act?.id ?? "",
                actNumber: act?.actNumber ?? "",
              }}
              onChange={setCurrentStep}
              setActOfWork={setActOfWork}
              disabled={disabled}
            />
          )}
        </StyledDetail>
      </StyledContent>
    </Modal>
  );
}

export default memo(ModalActOfWorkDetail);
